//As initMap is a callback on an async/defer script tag. This will be called after the elements on the page have been loaded. Therefore no requirement for a document.ready check
var initMap = function(){

    //map style, buildings and boundary polygons
    if($('section.directory-map').length || $('section.contact-map').length || $('section.aoi-map').length){

         //different zoom level dependent on screen size
         //zoom to bounds is not working robustly, zoomLevel is therefore set like this
        if($(window).width() < 1366 || $('section.contact-map').length){
            var zoomLevel = 15;
        }else{
            var zoomLevel = 16;
        }

        map = new google.maps.Map(document.getElementById('map'), {
            center: {
                lat: 55.903500,
                lng: -4.308783
            },
            zoom: zoomLevel,
            scrollwheel: false,
            disableDefaultUI: true,
            zoomControl:true,
            zoomControlOptions:{
                position:google.maps.ControlPosition.LEFT_BOTTOM
            },
            streetViewControl:true,
            streetViewControlOptions:{
                position:google.maps.ControlPosition.LEFT_BOTTOM
            },
            gestureHandling: 'cooperative', //enable holding Ctrl to zoom whilst scrolling past map element
            styles:basemap
        });

        google.maps.event.addListenerOnce(map, 'idle', function(){ //idle event with addListenerOnce acts as a googleMaps.ready event

            map.data.addGeoJson(campus_boundaries);

            if($('section.directory-map').length || $('section.contact-map').length){
                // map.data.loadGeoJson('data/buildings.json', null, function(features){ //localhost file
                map.data.loadGeoJson('/umbraco/surface/json/Building?pageId=1269', null, function(features){
    
                    if($('section.directory-map').length){
                        //tooltip on hover
                        google.maps.event.addListener(map.data, 'mouseover', function(event){
                            if(event.feature.getProperty('building') == 'true'){ //only show tooltip if hovering over a building polygon
                                mapTooltip(event.feature.getProperty('name') + ', ' + event.feature.getProperty('campus'));
                            }
                        });
            
                        google.maps.event.addListener(map.data, 'mouseout', function(){
                            if($mapTooltip != undefined){
                                $mapTooltip.remove();
                            }
                        });
                        
                        google.maps.event.addListener(map.data, 'click', function(event){
                            if(event.feature.getProperty('building') == 'true'){
                                map.data.revertStyle(); //remove any 'click overwritten styles'
                                map.data.overrideStyle(event.feature, { //overwrite style of clicked (selected) building
                                    fillColor: '#303030',
                                    strokeColor:'#F12A66',
                                    strokeWeight:1
                                });
            
                                var id = event.feature.getProperty('id'); //0 based index
                                map_slider.flickity('select', id + 1);
                            }
                        });
                    }
    
                });

                kelvin_marker = new google.maps.Marker({
                    position: {
                        lat: 55.903500, 
                        lng: -4.311700
                    },
                    map: map,
                    optimized:false, //ensure svg marker renders in IE
                    icon:{
                        url:'images/kelvin_label.png'
                    },
                    zIndex: 1,
                    title:"Kelvin Campus"
                });

                todd_marker = new google.maps.Marker({
                    position: {
                        lat: 55.905900,
                        lng: -4.303600
                    },
                    map: map,
                    optimized:false, //ensure svg marker renders in IE
                    icon:{
                        url:'images/todd_label.png'
                    },
                    zIndex: 1,
                    title:"Todd Campus"
                });
            }
        
        });

        map.data.setStyle(function(feature) {

            if(feature.getProperty('name') == 'Todd Campus'){
                var color = '#72C62A',
                    weight = 2,
                    opacity = 0,
                    zeeIndex = 1;
            }

            if(feature.getProperty('name') == 'Kelvin Campus'){
                var color = '#11A2E8',
                    weight = 2,
                    opacity = 0,
                    zeeIndex = 1;
            }

            if(feature.getProperty('building') == 'true'){
                var weight = 1,
                    color = '#d5d5d5',
                    opacity = 1,
                    zeeIndex = 10;
            }

            return{
                strokeColor:color,
                strokeWeight:weight,
                fillOpacity:opacity,
                fillColor:'#888888',
                zIndex:zeeIndex
            };
        });

        $('#map').mousedown(function(){
            $('section.contact-map .map-card').addClass('fade');
        });
    
        $('#map').mouseup(function(){
            $('section.contact-map .map-card').removeClass('fade');
        });

    }

    //directory map page functionality
    if($('section.directory-map').length){

        //populate map cards with json data
        // $.getJSON("data/buildings.json", function(data){ //localhost file
        $.getJSON("/umbraco/surface/json/Building?pageId=1269", function(data){
            $.each(data.features, function(i){
                var tenantsList = '';

                if(data.features[i].properties.tenants.length > 0){ //loop through tenants for each building, and add to string array before appending to ul.companies
                    for(var x = 0; x < data.features[i].properties.tenants.length; x++){
                        var buildingNameFormatted = data.features[i].properties.tenants[x].Name.trim().split(' ').join('-').replace("'", "").toLowerCase(); //building name data attribute used for 'click to table row' functionality
                        tenantsList += '<li data-company-name="' + buildingNameFormatted + '">' + data.features[i].properties.tenants[x].Name + '</li>';
                    }
                }

                if(data.features[i].properties.available == "true"){
                    availablity = '<li class="availability available"><a href="/contact-us#contactForm">Available Space</a></li>';
                }else{
                    availablity = '';
                }

                buildingCampus = data.features[i].properties.name + '-' + data.features[i].properties.campus;

                //replace " " and "'" with dash
                buildingCampusFormatted = buildingCampus.split(' ').join('-').split("'").join('-').toLowerCase();

                $('section.directory-map ul.buildings').append('\
                    <li data-building-id="' + buildingCampusFormatted + '">\
                        <figure><img src="' + data.features[i].properties.image + '" class="img-cover"></figure>\
                        <div class="text">\
                            <span class="building-name">' + data.features[i].properties.name + ', ' + data.features[i].properties.campus + '</span>\
                            <p class="desc">' + data.features[i].properties.desc + '</p>\
                            <span class="whos-here">Who\'s here</span>\
                            <ul class="companies">' + tenantsList + '</ul>\
                            <ul class="icons">'
                                + availablity +
                                '<li class="streetview">\
                                    <a href="' + data.features[i].properties.streetview + '" target="_blank">Browse in Street View</a>\
                                </li>\
                            </ul>\
                        </div>\
                    </li>\
                ');
                
            });

            tenantsList = '';

            map_slider = $('ul.buildings'); //variable has to be global
            map_slider.flickity({
                cellAlign:'left',
                contain: true,
                draggable:false,
                wrapAround:true,
                adaptiveHeight: true,
                groupCells:true,
                pauseAutoPlayOnHover:false,
                prevNextButtons:false,
                pageDots: false,
                imagesLoaded:true,
            });

            //Scroll to first entry of company in companies table when clicking on 'whos here' in map card
            $('ul.companies li').click(function(){
               var companyName = $(this).data('company-name');

                var relevantRow = $('table.companies').find('tr[data-company-name="' + companyName + '"]');
                var rowOffset = (relevantRow.offset().top + $('body').scrollTop()) - 200;

                relevantRow.addClass('highlight');

                $('html, body').animate({
                    scrollTop:rowOffset
                }, 500, function(){
                    setTimeout(function(){
                        relevantRow.removeClass('highlight');
                    }, 2000);
                });
            });
        });

        //Scroll to map when clicking row in companies table
        var mapOffset = $('.map-container').offset().top - 150;
        $('table.companies td, table.available-spaces td').click(function(){
            var buildingID = $(this).closest('tr').data('building-id');

            $('html, body').animate({
                scrollTop:mapOffset
            }, 500);

            //trigger click on building to toggle relevant buildings map card
            map.data.forEach(function(feature){
                if(feature.getProperty('buildingid') === buildingID){
                    google.maps.event.trigger(map.data, 'click', {
                        feature: feature
                    });
                }
            });
        });

    }

    if($('section.contact-map').length || $('section.aoi-map').length){
        
        reception_marker = new google.maps.Marker({
            position: {
                lat: 55.901366,
                lng: -4.313792
            },
            map: map,
            optimized:false, //ensure svg marker renders in IE
            icon:{
                url:'images/map/icon-pink.png'
            },
            zIndex: 1,
            title:"Reception building"
        });

        var receptionInfoWindow = new google.maps.InfoWindow({
            content: '<p class="marker-label">Reception building</p>'
        }).open(map, reception_marker);
    }

    //add AOI markers to the map
    if($('section.aoi-map').length){

        map.data.setStyle(function(feature) {

            if(feature.getProperty('name') == 'Todd Campus'){
                var color = '#F12A66',
                    weight = 2,
                    opacity = 0,
                    zeeIndex = 1;
            }

            if(feature.getProperty('name') == 'Kelvin Campus'){
                var color = '#F12A66',
                    weight = 2,
                    opacity = 0,
                    zeeIndex = 1;
            }

            return{
                strokeColor:color,
                strokeWeight:weight,
                fillOpacity:opacity,
                fillColor:'#888888',
                zIndex:zeeIndex
            };
        });

        map.set('styles',
            [
                {
                    "featureType": "all",
                    "elementType": "labels",
                    "stylers": [
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "labels",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "transit.station",
                    "elementType": "labels",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                }
            ]
        );
        
        aoi_markers = [];
        info_windows = [];
        // $.getJSON("data/aoi_markers.json", function(data){ //localhost
        $.getJSON("/umbraco/surface/json/AOIMarkers?pageId=1267", function(data){
            $.each(data, function(i){
                aoi_marker = new google.maps.Marker({
                    position: {
                        lat: data[i].lat,
                        lng: data[i].lng
                    },
                    map: map,
                    optimized:false, //ensure svg marker renders in IE
                    icon:{
                        url:'images/map/icon-' + data[i].category + '.png',
                        anchor: new google.maps.Point(15, 17)
                    },
                    zIndex: 1,
                    title:data[i].name
                });

                aoi_markers[i] = aoi_marker;

                google.maps.event.addListener(aoi_marker, 'mouseover', function(event){
                    if(!infowindow.opened){ //only show mouseover if infowindow not open
                        mapTooltip(data[i].name);
                    }
                });

                google.maps.event.addListener(aoi_marker, 'mouseout', function(){
                    if($mapTooltip != undefined){
                        $mapTooltip.remove();
                    }
                });

                //used where mouseover is not available. i.e. touch screens
                var infowindow = new google.maps.InfoWindow({
                    content: '<p class="marker-label">' + data[i].name + '</p>',
                    opened:false //add custom property to use for checking if infowindow is open
                });

                info_windows[i] = infowindow;

                //Required for touch devices, where hover is not available
                google.maps.event.addListener(aoi_marker, 'click', function(event){

                    if($mapTooltip != undefined){ //remove mouseover tooltip when clicking to show infowindow
                        $mapTooltip.remove();
                    }

                    //if clicked marker infowindow is open, closed all marker windows
                    if(infowindow.opened){
                        info_windows.forEach(function(item){
                            item.close();
                            item.opened = false;
                        });

                    //if the clicked marker infowindow is not open, close all marker windows and then open the clicked marker window
                    }else{
                        info_windows.forEach(function(item){
                            item.close();
                            item.opened = false;
                        });
    
                        infowindow.open(map, this);
                        infowindow.opened = true;
                    }

                });

            });

            //fit markers to map
            $(window).resize(function(){
                var bounds = new google.maps.LatLngBounds();
                for (var i = 0; i < aoi_markers.length; i++) {
                    bounds.extend(aoi_markers[i].getPosition());
                }
                map.fitBounds(bounds);
            }).resize();

        });
    }
}

//default styling with POI business and government labels turned off. map lightened a bit to make the geojson pop more
//These are the only labels that overlap with the science park buildings
var basemap = [
    {
        "featureType": "all",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "lightness": "10"
            },
            {
                "gamma": "1.4"
            }
        ]
    },
    {
        "featureType": "poi.business",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi.government",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    }
]

var campus_boundaries = {
    "type": "FeatureCollection",
    "name": "campus_boundaries_2",
    "crs": {
        "type": "name",
        "properties": {
            "name": "urn:ogc:def:crs:OGC:1.3:CRS84"
        }
    },
    "features": [{
            "type": "Feature",
            "properties": {
                "id": 1,
                "name": "Kelvin Campus"
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [-4.308396244434717, 55.901077195862605],
                        [-4.309051961845626, 55.900414023999431],
                        [-4.309660028436651, 55.900494722471393],
                        [-4.310238061925528, 55.900434100218142],
                        [-4.310971080264604, 55.900384303297336],
                        [-4.311736174339771, 55.900285317634832],
                        [-4.312398274981741, 55.900186331719731],
                        [-4.313075088971312, 55.900178082882071],
                        [-4.313722476265683, 55.900153336358564],
                        [-4.314281583474458, 55.900079096693339],
                        [-4.314783241603672, 55.90002990769181],
                        [-4.315364849701941, 55.900035669373494],
                        [-4.315792079356964, 55.900209986727546],
                        [-4.316109389773088, 55.900329657238238],
                        [-4.31642615761457, 55.900436587373882],
                        [-4.316885845999544, 55.900656512566869],
                        [-4.317194826299131, 55.90082148695209],
                        [-4.317503806598718, 55.900928719926249],
                        [-4.3179010669839, 55.900994709301465],
                        [-4.318357180759481, 55.901110190437961],
                        [-4.317533233293916, 55.902009281814024],
                        [-4.315944191753186, 55.901374146030435],
                        [-4.315620498106, 55.901506123153119],
                        [-4.315193811025619, 55.901572111546066],
                        [-4.314693557207241, 55.901638099826769],
                        [-4.314237443431661, 55.901687590963618],
                        [-4.314105023303267, 55.901679342445199],
                        [-4.31403145656527, 55.90175357904787],
                        [-4.314105023303267, 55.901794821543525],
                        [-4.314266870126859, 55.901976288003432],
                        [-4.314222730084061, 55.902372211878301],
                        [-4.314134449998465, 55.902528930629018],
                        [-4.313943176479674, 55.902619662247915],
                        [-4.313722476265683, 55.902669152132411],
                        [-4.313251649142503, 55.902735138546838],
                        [-4.312942668842918, 55.90281762140701],
                        [-4.312780822019325, 55.902999083081994],
                        [-4.312574835152933, 55.903427989120907],
                        [-4.312589548500533, 55.903469229836247],
                        [-4.313045662276114, 55.90393937089182],
                        [-4.313678336222885, 55.904557968861759],
                        [-4.314266870126859, 55.90515181363098],
                        [-4.314605277121644, 55.905506466587077],
                        [-4.313868205430843, 55.90591248057892],
                        [-4.312471841719738, 55.904607456273155],
                        [-4.310265924729115, 55.90260778182099],
                        [-4.309234905247879, 55.901704087995192],
                        [-4.308396244434717, 55.901077195862605]
                    ]
                ]
            }
        },
        {
            "type": "Feature",
            "properties": {
                "id": 2,
                "name": "Todd Campus"
            },
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [-4.300817625690991, 55.903944738378186],
                        [-4.301466257715419, 55.904087835304374],
                        [-4.302010651576596, 55.904195059248927],
                        [-4.302893452432557, 55.904252795096284],
                        [-4.303452559641332, 55.904277539004553],
                        [-4.304114660283304, 55.904252795096284],
                        [-4.304982747791666, 55.904211555214083],
                        [-4.305262301396054, 55.904203307232379],
                        [-4.305409434872047, 55.904417754186603],
                        [-4.305644848433637, 55.904533225132383],
                        [-4.305924402038024, 55.904582712575333],
                        [-4.306233382337611, 55.904491985548354],
                        [-4.306380515813604, 55.904401258309136],
                        [-4.30714560988877, 55.904508481387218],
                        [-4.307631150359549, 55.90461570416889],
                        [-4.307895990616338, 55.904714678781176],
                        [-4.308190257568325, 55.904854892382922],
                        [-4.308911211600694, 55.904813653140906],
                        [-4.309485032157068, 55.904681687271811],
                        [-4.309808725804254, 55.904582712575333],
                        [-4.309970572627847, 55.904475489702463],
                        [-4.309632165633063, 55.903972363032601],
                        [-4.310794520093411, 55.903906378722958],
                        [-4.3110593603502, 55.903997107119785],
                        [-4.311338913954587, 55.903988859092479],
                        [-4.31353120274689, 55.90615802984496],
                        [-4.312868782943367, 55.906491509207328],
                        [-4.312096220487857, 55.906170642717022],
                        [-4.31157627440165, 55.905796101304439],
                        [-4.311064881845069, 55.905676519252843],
                        [-4.310926940221806, 55.906257000521421],
                        [-4.310832276905145, 55.906885508518009],
                        [-4.310250126232235, 55.906860462134304],
                        [-4.309728775835195, 55.906853610155629],
                        [-4.309314855216939, 55.906839602063023],
                        [-4.308955351643492, 55.906718860338835],
                        [-4.308646371343905, 55.906570405997371],
                        [-4.308293251001521, 55.906347723419657],
                        [-4.307910703963937, 55.906042563736534],
                        [-4.307636991015972, 55.905839951551243],
                        [-4.30724557128721, 55.905689615303601],
                        [-4.306846364016556, 55.905553770102792],
                        [-4.306471923682421, 55.90560059049961],
                        [-4.30567057285482, 55.905694410044156],
                        [-4.304977641206816, 55.905782951564987],
                        [-4.304576391302218, 55.905841955327908],
                        [-4.303835202427383, 55.905924127421919],
                        [-4.303571447319874, 55.905963182828216],
                        [-4.303311809396402, 55.905904537099488],
                        [-4.302790458999361, 55.905770088127795],
                        [-4.302290205180984, 55.905654925001144],
                        [-4.301583964496214, 55.905506466587077],
                        [-4.300608064215203, 55.905397761314923],
                        [-4.300267119886073, 55.90549409503614],
                        [-4.299908861042683, 55.90542468717728],
                        [-4.300224402193416, 55.905268747836899],
                        [-4.300817625690991, 55.903944738378186]
                    ]
                ]
            }
        }
    ]
}

//custom tooltip
var $mapTooltip;
function mapTooltip(info){
    $mapTooltip = $('<div>',{class:'mapTooltip'});
    $mapTooltip.text(info);
    $mapTooltip.appendTo('body');

    $(document).on('mousemove', function(e){
        $mapTooltip.css({
           left: e.clientX,
           top: e.clientY
        });
    });
}