$(function () {

	if($('section.caseStudies ul.studies li').length > 3){
		$('#load-more').show();
	}else{
		$('#load-more').hide();
    }
    
    //Show first 6 blocks on page load
    $('section.caseStudies ul.studies li').slice(0, 6).show();

    $('#load-more').on('click', function (e) {
        e.preventDefault();
        $('section.caseStudies ul.studies li:hidden').slice(0, 3).slideDown();
        if ($('section.caseStudies ul.studies li:hidden').length == 0) {
            $('#load-more').fadeOut('slow');
        }
    });
});