$(function(){

    var menuTog = $('#menuTog'),
        body = $('body'),
        menu = $('#mobNav'),
        isOpen = false;

    var menuToggle = function(){
        if(isOpen){ // Menu is open
            body.removeClass('menu_open');
            body.css('overflow','visible');
            menu.fadeOut(0);
            isOpen = false;
            menuTog.removeClass('open');
        } else{ // Menu is closed
            menu.fadeIn(0);
            setTimeout(function(){
                body.css('overflow', 'hidden');
            },10);
            isOpen = true;
            menuTog.addClass('open');
        }
    }

    menuTog.click(function(){
        menuToggle();
    });
});