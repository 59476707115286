$(function(){

    var hero_slider = $('ul.travel-slides');
    hero_slider.flickity({
        cellAlign:'left',
        contain: true,
        draggable:true,
        wrapAround:true,
        pauseAutoPlayOnHover:false,
        prevNextButtons:true,
        pageDots: false,
        imagesLoaded:true
    });

    if($('ul.image-slides li').length > 1){
        var image_slider = $('ul.image-slides');
        image_slider.flickity({
            cellAlign:'left',
            autoPlay:4000,
            contain: true,
            draggable:true,
            wrapAround:true,
            pauseAutoPlayOnHover:true,
            prevNextButtons:false,
            pageDots: false,
            imagesLoaded:true
        });
    }

    var stats_slider = $('ul.stats-slides');
    stats_slider.flickity({
        cellAlign:'left',
        contain: true,
        draggable:true,
        wrapAround:true,
        groupCells:true,
        pauseAutoPlayOnHover:false,
        prevNextButtons:true,
        pageDots: false,
        imagesLoaded:true
    });

    var case_slider = $('ul.case-slides');
    case_slider.flickity({
        cellAlign:'left',
        contain: true,
        draggable:true,
        adaptiveHeight: true,
        wrapAround:false,
        groupCells:true,
        pauseAutoPlayOnHover:false,
        prevNextButtons:true,
        pageDots: false,
        imagesLoaded:true
    });

    var blocks_slider = $('ul.blocks');
    blocks_slider.flickity({
        cellAlign:'left',
        contain: true,
        draggable:true,
        wrapAround:true,
        groupCells:true,
        pauseAutoPlayOnHover:false,
        prevNextButtons:false,
        pageDots: false,
        imagesLoaded:true,
        watchCSS: true
    });

    $('ul.blocks + .slider-controls .previous').click(function(){
        blocks_slider.flickity('previous');
    });
    
    $('ul.blocks + .slider-controls .next').click(function(){
        blocks_slider.flickity('next');
    });

});