$(function(){
    $('section.hero-banner .down').click(function(){
        $('html,body').animate({
            scrollTop : $('section.hero-banner').offset().top + $('section.hero-banner').height()
        }, 1000);

        //fallback for arrow working in Edge browser. This may not be required when Edge starts using Chromium
        if(window.navigator.userAgent.indexOf("Edge") > -1){
            window.location.hash = 'intro';
            window.location.hash = '';
        }
    });
});