var lightBox = function(content,callback){
	var lightbox = $('<section>',{'id':'lightBox'});
	var	frame = $('<div>',{'class':'frame'});
	var close = $('<div>',{'class':'close'});	
    frame.append(close);
	lightbox.append(frame);
    frame.append(content);
    lightbox.hide().appendTo('body');
	lightbox.fadeIn();
	
    if(typeof callback == 'function'){
    	callback();
	}
	
    if(content.outerHeight() > lightbox.height()){
        content.addClass('overflow');
	}
	
    $('body').addClass('vidbox-open');
	$('body').trigger('lightbox-open');
	
    close.click(function(){
    	lightbox.fadeOut();
    	$('body').removeClass('vidbox-open');
    	setTimeout(function(){
    		lightbox.empty().remove();
    	},300);
    });
}

function ytEmbed(id,controls) {
    var iframe = document.createElement("iframe");
    var embed = "https://www.youtube.com/embed/ID?vq=hd1080&autoplay=1";
    if(!controls){
    	embed = embed + "&controls=0&showinfo=0&autohide=2&disablekb=1&loop=1&modestbranding=1&rel=0&hd=1";
    }
    iframe.setAttribute("src", embed.replace("ID", id));
    iframe.setAttribute("frameborder", "0");
    iframe.setAttribute("allowfullscreen", "1");
    return iframe
}

var videoBox = function(vid){
	var player = document.createElement("div");
	var	frame = $('<div>',{'class':'vid-frame'});
    player = ytEmbed(vid,1);
	frame.append(player);
    lightBox(frame,function(){
    	frame.fitVids();
    });
}

$(function(){

	//popup videos
	$('[data-video]').click(function(){
		videoBox($(this).attr('data-video'));
	});

	//home page hero banner video
	$(window).resize(function(){
		//if video container AR is less than 16/9 AR, then set height to make the height 100% of the video container and then overflow width
		if($('.background-video').width() / $('.background-video').height() < 16/9){
			$('.background-video').find('.video-wrapper').css('width', (16 * $('.background-video').height() / 9));
		}else{
			$('.background-video').find('.video-wrapper').css('width', "100%");
		}
	}).resize();
	
});