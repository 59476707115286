$(function(){

    if('IntersectionObserver' in window && $('.remove2animate').length){

        var animatedElements = document.querySelectorAll('.remove2animate');

        var animationObserver = new IntersectionObserver(function(animatedElements){
            
            for(var i = 0; i < animatedElements.length; i++){
                var scope = $(animatedElements[i].target);
    
                if(animatedElements[i]['isIntersecting'] === true){ //when element comes into viewport

                    //bump in sections
                    if(scope.hasClass('remove2animate')){
                        scope.removeClass('remove2animate');
                    }

                    if(scope.hasClass('locationStats')){
                        $('.counter').each(function() {
                            var $this = $(this),
                                countTo = $this.attr('data-count');
                                countTo = countTo.split(',').join('').split('+').join('');
                            
                            $({countNum: $this.text()}).animate({
                                countNum: countTo
                            },
                            {
                                duration: 2000,
                                easing:'linear',
                                step: function() {
                                $this.text(Math.floor(this.countNum));
                            },
                                complete: function() {
                                    $this.text($this.attr('data-count'));
                                }
                            });
                        });
                    }
    
                    //once element is in view, we don't have to observe it anymore
                    animationObserver.unobserve(animatedElements[i].target);
                }
            }

        }, {threshold:0.1}); //show element when 10% of it's height enters viewport

        //init observer on all animatable elements
        for(var i = 0; i < animatedElements.length; i++){
            animationObserver.observe(animatedElements[i]);
        }

        //check if the first animatable section is above the fold. if it is - animate it straight away without the requirement of scrolling
        //The 200 value is the transformed offset applied by .remove2animate
        //This isn't required within the IE fallback as this will only ever run on tablet/mobile
        if(($('section.remove2animate').first().offset().top - 149) < $(window).height()){
            $('section.remove2animate').first().removeClass('remove2animate');
        }

    }else{ //if intersectionObserver is not supported in browser, use scroll method for checking when elements come into view

        $('body').scroll(function(){
    
            var windowBottom = $('body').scrollTop() + $(window).height(),
                animateScrollPoint = $(window).height()*0.15;
    
            $('.remove2animate').each(function(){
    
                if($(this).offset().top + animateScrollPoint < windowBottom){
    
                    //bump in sections
                    if($(this).hasClass('remove2animate')){
                        $(this).removeClass('remove2animate');
                    }

                    if($(this).hasClass('locationStats')){
                        $('.counter').each(function() {
                            var $this = $(this),
                                countTo = $this.attr('data-count');
                                countTo = countTo.split(',').join('').split('+').join('');
                            
                            $({countNum: $this.text()}).animate({
                                countNum: countTo
                            },
                            {
                                duration: 2000,
                                easing:'linear',
                                step: function() {
                                $this.text(Math.floor(this.countNum));
                            },
                                complete: function() {
                                    $this.text($this.attr('data-count'));
                                }
                            });
                        });
                    }
                }
    
            });
    
            //if scroll gets to page bottom, force remove2animate on all remaining elements that still have class.
            //This is required when there is very short content meaning the animateScrollPoint cannot be reached to trigger animation
            if(windowBottom == $(document).height()){
                $('.remove2animate').removeClass('remove2animate');
            }
    
        }).scroll();
    }
    
});